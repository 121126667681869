nav {
    position: relative;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    max-width: 1400px;
    background: var(--glass);
    backdrop-filter: blur(20px);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 15px;
    z-index: 1000;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo Styles */
.nav-brand {
    display: flex;
    align-items: center;
}

.logo-nav {
    display: flex;
    align-items: center;
    gap: 15px;
    text-decoration: none;
    color: var(--text);
}

.logo-png {
    width: 40px; /* Rozmiar dostosowany do poprzedniego SVG */
    height: 40px;
    object-fit: contain; /* Zachowanie proporcji obrazu */
}

.brand-name {
    font-size: 1.5rem;
    font-weight: bold;
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Navigation Links */
.nav-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-link {
    color: var(--text);
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 12px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.nav-link::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background: var(--gradient);
    transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
    width: 80%;
}

.nav-link:hover {
    background: var(--glass-border);
}

.nav-icon {
    color: var(--icon-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Action Button */
.nav-actions {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid var(--glass-border);
}

.nav-btn {
    padding: 10px 20px;
    border-radius: 12px;
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.nav-btn.primary {
    background: var(--gradient);
    color: var(--bg-darker);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

.nav-btn.primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 255, 140, 0.5);
}

/* Mobile Menu */
.menu-toggle {
    display: none;
    flex-direction: column;
    gap: 6px;
    cursor: pointer;
    padding: 10px;
}

.menu-toggle span {
    display: block;
    width: 30px;
    height: 2px;
    background: var(--primary);
    transition: all 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    nav {
        top: 10px;
        width: calc(100% - 20px);
        padding: 10px;
    }

    .nav-right {
        position: fixed;
        top: 80px; /* Adjusted to account for nav height */
        left: 10px;
        right: 10px;
        background: var(--glass);
        border: 1px solid var(--glass-border);
        border-radius: 16px;
        padding: 20px;
        flex-direction: column;
        transform: translateY(-20px);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        backdrop-filter: blur(20px);
    }

    .nav-right.active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }

    .nav-links {
        flex-direction: column;
        width: 100%;
    }

    .nav-actions {
        margin: 15px 0 0;
        padding: 15px 0 0;
        border-left: none;
        border-top: 1px solid var(--glass-border);
        width: 100%;
    }

    .menu-toggle {
        display: flex;
    }
}