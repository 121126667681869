/* Shop Container */
.shop-container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

/* License Grid */
.license-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.license-card {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease;
}

.license-card:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    background: var(--glass-border);
}

.license-card h2 {
    font-size: 1.5em;
    color: var(--text);
    margin-bottom: 10px;
}

.license-card .price {
    font-size: 1.2em;
    color: var(--primary);
    margin-bottom: 20px;
}

/* Select Button */
.select-btn {
    padding: 10px 20px;
    border-radius: 12px;
    background: var(--gradient);
    border: none;
    color: var(--bg-darker);
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.select-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

.select-btn:disabled {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    color: var(--text-secondary);
    cursor: not-allowed;
    box-shadow: none;
}

.select-btn.out-of-stock:hover {
    transform: none;
    box-shadow: none;
}

/* Payment Info */
.payment-info {
    margin-top: 40px;
    text-align: center;
}

.accepted-payments h3 {
    color: var(--text);
    margin-bottom: 20px;
}

.crypto-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.crypto-icons svg {
    font-size: 2em;
    color: var(--primary);
}

/* Donate Section */
.donate-section {
    margin-top: 60px;
    text-align: center;
}

.donate-title {
    font-size: 2.8em;
    color: var(--primary);
    margin-bottom: 20px;
}

.donate-description {
    color: var(--text-secondary);
    font-size: 1.1em;
    margin-bottom: 30px;
}

/* Crypto List */
.crypto-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.crypto-item {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 20px;
    transition: all 0.3s ease;
}

.crypto-item:hover {
    border-color: var(--primary);
    background: var(--glass-border);
}

.crypto-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.crypto-icon {
    font-size: 1.8em;
    color: var(--primary);
}

.crypto-header span {
    color: var(--text);
    font-size: 1.2em;
}

.crypto-address {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--bg-darker);
    padding: 10px;
    border-radius: 8px;
}

.crypto-address code {
    color: var(--text-secondary);
    font-size: 0.9em;
    word-break: break-all;
    flex-grow: 1;
}

.copy-button {
    background: var(--gradient);
    border: none;
    padding: 8px;
    border-radius: 8px;
    color: var(--bg-darker);
    cursor: pointer;
    transition: all 0.3s ease;
}

.copy-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        padding: 40px 20px;
    }

    .license-grid {
        grid-template-columns: 1fr;
    }

    .crypto-list {
        padding: 0 20px;
    }

    .crypto-address {
        flex-direction: column;
        text-align: center;
    }

    .crypto-address code {
        margin-bottom: 10px;
    }
}