/* Download Section Styles */
.download-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.download-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 40px;
    border-radius: 12px;
    font-size: 1.2em;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.download-btn.primary {
    background: var(--gradient);
    color: var(--bg-darker);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

.download-btn.primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 255, 140, 0.5);
}

.other-platforms {
    display: flex;
    gap: 15px;
}

.platform-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    border-radius: 12px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    color: var(--text);
    cursor: pointer;
    transition: all 0.3s ease;
}

.platform-btn:hover {
    background: var(--glass-border);
    transform: translateY(-2px);
}

/* Features Grid */
.features-grid {
    padding: 80px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
}

.feature-card {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    background: var(--glass-border);
}

.feature-icon {
    font-size: 2em;
    color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: rgba(0, 255, 140, 0.1);
    border-radius: 12px;
    margin: 0 auto 20px;
}

/* Installation Section */
.installation {
    padding: 80px 40px;
    background: var(--bg-darker);
}

.installation-container {
    max-width: 1400px;
    margin: 0 auto;
}

.installation h2 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 50px;
}

.steps-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.step-card {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 30px;
    text-align: center;
    transition: all 0.3s ease;
    position: relative;
}

.step-card:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    background: var(--glass-border);
}

.step-number {
    width: 40px;
    height: 40px;
    background: var(--gradient);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2em;
    color: var(--bg-darker);
    margin: 0 auto 20px;
}

.step-card h3 {
    color: var(--text);
    margin-bottom: 10px;
}

.step-card p {
    color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero {
        padding: 120px 20px 60px;
    }

    .features-grid,
    .installation {
        padding: 40px 20px;
    }

    .other-platforms {
        flex-direction: column;
        width: 100%;
    }

    .platform-btn {
        width: 100%;
        justify-content: center;
    }
}


.download-btn.disabled {
    background: var(--glass-border);
    color: var(--text-secondary);
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
}

.download-btn.disabled:hover {
    transform: none;
    box-shadow: none;
}
