/* Server Lookup Styles */
.server-lookup {
    padding: 0 40px 80px;
    max-width: 1400px;
    margin: 0 auto;
}

.lookup-container {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 40px;
}

/* Server Type Toggle */
.server-type-toggle {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.type-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 24px;
    border-radius: 12px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    color: var(--text);
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.type-btn.active {
    background: var(--gradient);
    color: var(--bg-darker);
    border: none;
}

.type-btn:hover:not(.active) {
    background: var(--glass-border);
}

/* Lookup Form */
.lookup-form {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}

.server-input {
    flex: 1;
    padding: 15px 20px;
    border-radius: 12px;
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    color: var(--text);
    font-size: 1em;
}

.server-input:focus {
    outline: none;
    border-color: var(--primary);
}

.lookup-btn {
    padding: 15px 30px;
    border-radius: 12px;
    background: var(--gradient);
    border: none;
    color: var(--bg-darker);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.lookup-btn:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

.lookup-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* Server Info Grid */
.server-info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
}

.info-card {
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    transition: all 0.3s ease;
}

.info-card:hover {
    transform: translateY(-2px);
    border-color: var(--primary);
}

.info-icon {
    font-size: 1.5em;
    color: var(--primary);
    margin-bottom: 15px;
}

.info-value {
    font-size: 1.2em;
    color: var(--text);
    margin-top: 10px;
}

/* Status Badge */
.status-badge {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 0.9em;
    margin-top: 10px;
}

.status-badge.online {
    background: rgba(0, 255, 140, 0.1);
    color: var(--primary);
}

.status-badge.offline {
    background: rgba(255, 0, 0, 0.1);
    color: var(--error);
}

/* Additional Info Sections */
.additional-info {
    margin-top: 40px;
    display: grid;
    gap: 30px;
}

.plugins-section,
.mods-section {
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 20px;
}

.tags-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
}

.tag {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 12px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 8px;
    font-size: 0.9em;
}

/* Error Message */
.error-message {
    color: var(--error);
    text-align: center;
    padding: 15px;
    border: 1px solid var(--error);
    border-radius: 12px;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .server-lookup {
        padding: 0 20px 40px;
    }

    .lookup-container {
        padding: 20px;
    }

    .lookup-form {
        flex-direction: column;
    }

    .server-type-toggle {
        flex-direction: column;
    }

    .type-btn {
        width: 100%;
        justify-content: center;
    }
}