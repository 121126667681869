.jar-decompiler-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    font-weight: 500;
}

.hero {
    padding: 160px 0 80px;
    text-align: center;
}

.hero-eyebrow2 {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 100px;
    background: rgba(122, 255, 193, 0.1);
    border: 1px solid rgba(122, 255, 193, 0.2);
    margin-bottom: 30px;
}

.status-indicator {
    width: 8px;
    height: 8px;
    background: var(--primary);
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.hero h1 {
    font-size: 4em;
    line-height: 1.2;
    margin-bottom: 30px;
}

.gradient-text {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.content-section {
    padding: 5rem 0;
}

.upload-section {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 2rem;
}

.upload-area {
    border: 2px dashed var(--glass-border);
    border-radius: 16px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.upload-area:hover {
    border-color: var(--primary);
    background: rgba(122, 255, 193, 0.05);
}

.upload-icon {
    font-size: 3rem;
    color: var(--primary);
    margin-bottom: 1rem;
}

.upload-area p {
    color: var(--text-secondary);
    text-align: center;
}

.file-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    color: var(--text-secondary);
}

.file-error {
    color: var(--error);
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.progress-bar {
    height: 20px;
    background: rgba(122, 255, 193, 0.1);
    border-radius: 10px;
    margin: 1.5rem 0;
    overflow: hidden;
    position: relative;
}

.progress {
    height: 100%;
    background: var(--gradient);
    transition: width 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.progress-text {
    color: var(--bg-darker);
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary {
    background: var(--primary);
    color: var(--bg-darker);
}

.btn-primary:hover {
    background: var(--primary-light);
}

.btn-secondary {
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.btn-secondary:hover {
    background: rgba(122, 255, 193, 0.1);
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background: var(--glass);
    color: var(--text);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 250px;
    justify-content: space-between;
}

.notification.success {
    border: 1px solid var(--primary);
}

.notification.error {
    border: 1px solid var(--error);
}

.close-icon {
    cursor: pointer;
    color: var(--text-secondary);
    transition: color 0.3s ease;
}

.close-icon:hover {
    color: var(--text);
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.7;
    }
}
