.resource-pack-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    font-weight: 500;
    animation: fadeIn 1s ease-out 0.3s both;
}

.hero {
    padding: 160px 0 80px;
    text-align: center;
}

.hero-eyebrow2 {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 100px;
    background: rgba(122, 255, 193, 0.1);
    border: 1px solid rgba(122, 255, 193, 0.2);
    margin-bottom: 30px;
}

.status-indicator {
    width: 8px;
    height: 8px;
    background: var(--primary);
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.hero h1 {
    font-size: 4em;
    line-height: 1.2;
    margin-bottom: 30px;
}

.gradient-text {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.content-section {
    padding: 5rem 0;
}

.plan-section,
.upload-section,
.results-section {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.plan-options {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0;
}

.plan {
    flex: 1;
    padding: 1.5rem;
    border-radius: 12px;
    background: rgba(122, 255, 193, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.plan:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.plan.active {
    background: rgba(122, 255, 193, 0.1);
    border: 1px solid rgba(122, 255, 193, 0.2);
}

.plan-icon {
    font-size: 2rem;
    color: var(--primary);
    margin-bottom: 1rem;
}

.plan h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--text);
    margin-bottom: -6px;
}

.plan p {
    margin-top: 15px;
    color: var(--text-secondary);
}

.license-input {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
    align-items: center;
}

.input-wrapper {
    position: relative;
    flex: 1;
}

.input-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--text-secondary);
}

.license-input input {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: none;
    border-radius: 6px;
    background: rgba(122, 255, 193, 0.1);
    color: var(--text);
    font-size: 1rem;
}

.license-input input::placeholder {
    color: var(--text-secondary);
}

.license-input input:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--primary);
}

.upload-area {
    border: 2px dashed var(--glass-border);
    border-radius: 16px;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.upload-area:hover {
    border-color: var(--primary);
    background: rgba(122, 255, 193, 0.05);
}

.upload-icon {
    font-size: 3rem;
    color: var(--primary);
    margin-bottom: 1rem;
}

.upload-area p {
    color: var(--text-secondary);
    text-align: center;
}

.file-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
    color: var(--text-secondary);
}

.file-error {
    color: var(--error);
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.progress-bar {
    height: 20px;
    background: rgba(122, 255, 193, 0.1);
    border-radius: 10px;
    margin: 1.5rem 0;
    overflow: hidden;
    position: relative;
}

.progress {
    height: 100%;
    background: var(--gradient);
    transition: width 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.progress-text {
    color: var(--bg-darker);
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
}

.action-buttons-vertical {
    display: flex;
    flex-direction: column; /* Ustawia przyciski w pionie */
    gap: 1rem; /* Odstęp między przyciskami */
    align-items: center; /* Wyśrodkowanie w poziomie */
    margin-top: 1.5rem;
}

.btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 6px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn-primary {
    background: var(--primary);
    color: var(--bg-darker);
}

.btn-primary:hover {
    background: var(--primary-light);
}

.btn-secondary {
    background: transparent;
    border: 1px solid var(--primary);
    color: var(--primary);
}

.btn-secondary:hover {
    background: rgba(122, 255, 193, 0.1);
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.results-content {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary);
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 0.7;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.7;
    }
}

.completed-section {
    text-align: center;
    padding: 4rem 2rem !important;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.thank-you-message {
    margin-bottom: 1rem;
}

.thank-you-message h2 {
    color: var(--primary);
    margin-bottom: 1rem;
}

.download-button,
.reset-button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 12px;
}

.progress-section label {
    font-weight: bold;
    margin-bottom: 5px;
}

