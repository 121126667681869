.error-page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    position: relative;
    overflow: hidden;
    background: radial-gradient(circle at top right, var(--bg-dark), var(--bg-darker));
}

.error-content {
    text-align: center;
    z-index: 1;
    max-width: 800px;
}

.error-404 {
    font-size: 6em;
    font-weight: bold;
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1;
    margin-bottom: 20px;
}

.error-title {
    font-size: 2em;
    color: var(--text);
    margin-bottom: 20px;
}

.error-message {
    color: var(--text-secondary);
    font-size: 1.2em;
    margin-bottom: 40px;
}

.error-navigation {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.nav-button {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 25px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    color: var(--text);
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
}

.nav-button:hover {
    border-color: var(--primary);
    background: var(--glass-border);
    transform: translateY(-2px);
}

.nav-icon {
    font-size: 1.2em;
    color: var(--primary);
}

/* Particle Background */
.error-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
}

.particle {
    position: absolute;
    background: var(--primary);
    border-radius: 50%;
    opacity: 0.3;
    animation: drift 15s infinite linear;
}

.particle-1 {
    width: 20px;
    height: 20px;
    top: 20%;
    left: 10%;
    animation-duration: 10s;
}

.particle-2 {
    width: 15px;
    height: 15px;
    top: 50%;
    left: 30%;
    animation-duration: 12s;
}

.particle-3 {
    width: 25px;
    height: 25px;
    top: 70%;
    left: 60%;
    animation-duration: 14s;
}

.particle-4 {
    width: 10px;
    height: 10px;
    top: 30%;
    left: 80%;
    animation-duration: 11s;
}

.particle-5 {
    width: 18px;
    height: 18px;
    top: 80%;
    left: 20%;
    animation-duration: 13s;
}

@keyframes drift {
    0% {
        transform: translate(0, 0);
        opacity: 0.3;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        transform: translate(100vw, 50vh);
        opacity: 0;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .error-404 {
        font-size: 4em;
    }

    .error-title {
        font-size: 1.5em;
    }

    .error-message {
        font-size: 1em;
    }

    .error-navigation {
        flex-direction: column;
        gap: 15px;
    }
}

@media (max-width: 480px) {
    .error-404 {
        font-size: 3em;
    }

    .nav-button {
        padding: 10px 20px;
    }
}