.particles-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    color: #ff0000;
    pointer-events: none;
}

.particles-container canvas {
    position: absolute;
    top: 0;
    left: 0;
}