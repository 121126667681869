/* Podstawowy styl przycisku */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: var(--gradient);
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease, transform 0.3s ease;
    z-index: 1000;
    transform: translateY(100px);
}

.scroll-to-top.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

/* Animacja hover */
.scroll-to-top:hover {
    background: var(--gradient);
    box-shadow: 0 8px 15px rgba(96, 255, 43, 0.4);
    transform: scale(1.1);
}

/* Ikona w przycisku */
.scroll-to-top svg {
    font-size: 1.5em;
    animation: fadeInUp 0.5s ease;
}

/* Animacja pojawiania się ikony */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Styl przycisku dla małych ekranów */
@media (max-width: 768px) {
    .scroll-to-top {
        width: 50px;
        height: 50px;
    }

    .scroll-to-top svg {
        font-size: 1.2em;
    }
}
