/* Player Tracker Styles */
.tracker-section {
    padding: 0 40px 80px;
    max-width: 1400px;
    margin: 0 auto;
}

.tracker-container {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 40px;
}

/* Search Container */
.search-container {
    margin-bottom: 40px;
}

.search-type-toggle {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.type-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 24px;
    border-radius: 12px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    color: var(--text);
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

.type-btn.active {
    background: var(--gradient);
    color: var(--bg-darker);
    border: none;
}

.type-btn:hover:not(.active) {
    background: var(--glass-border);
}

/* Search Form */
.search-form {
    display: flex;
    gap: 15px;
}

.search-input {
    flex: 1;
    padding: 15px 20px;
    border-radius: 12px;
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    color: var(--text);
    font-size: 1em;
}

.search-input:focus {
    outline: none;
    border-color: var(--primary);
}

.search-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 30px;
    border-radius: 12px;
    background: var(--gradient);
    border: none;
    color: var(--bg-darker);
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.search-btn:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 255, 140, 0.3);
}

/* Player Info */
.player-header {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 40px;
    padding: 20px;
    background: var(--bg-darker);
    border-radius: 16px;
    border: 1px solid var(--glass-border);
}

.player-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    border: 2px solid var(--primary);
}

.player-details h2 {
    font-size: 1.8em;
    margin-bottom: 5px;
}

.uuid-text {
    color: var(--text-secondary);
    font-family: monospace;
    margin-bottom: 10px;
}

.player-stats {
    display: flex;
    gap: 20px;
    color: var(--text-secondary);
}

.player-stats span {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* Server History */
.server-history h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
}

.server-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.server-card {
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 20px;
    transition: all 0.3s ease;
}

.server-card:hover {
    transform: translateY(-2px);
    border-color: var(--primary);
}

.server-card-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.server-icon {
    color: var(--primary);
    font-size: 1.2em;
}

.server-type {
    margin-left: auto;
    padding: 4px 12px;
    background: rgba(0, 255, 140, 0.1);
    border-radius: 100px;
    font-size: 0.8em;
    color: var(--primary);
}

.server-stats {
    display: grid;
    gap: 10px;
}

.stat-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 768px) {
    .tracker-section {
        padding: 0 20px 40px;
    }

    .tracker-container {
        padding: 20px;
    }

    .search-form {
        flex-direction: column;
    }

    .search-type-toggle {
        flex-direction: column;
    }

    .player-header {
        flex-direction: column;
        text-align: center;
    }

    .player-stats {
        flex-direction: column;
        align-items: center;
    }

    .server-grid {
        grid-template-columns: 1fr;
    }
}