.notify-container {
    position: fixed;
    top: 20px;
    right: 20px;
    background: var(--glass);
    backdrop-filter: blur(10px);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    min-width: 300px;
    max-width: 450px;
    z-index: 9999;
    animation: slideIn 0.3s ease forwards;
    overflow: hidden;
}

.notify-container.exit {
    animation: slideOut 0.3s ease forwards;
}

.notify-icon {
    font-size: 1.4em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notify-content {
    flex: 1;
    color: var(--text);
}

.notify-content p {
    margin: 0;
    line-height: 1.4;
}

.notify-close {
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.notify-close:hover {
    color: var(--text);
    transform: rotate(90deg);
}

.notify-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: var(--primary);
    animation: progress 5s linear forwards;
}

/* Types */
.notify-container.success {
    border-color: rgba(0, 255, 140, 0.2);
}

.notify-container.success .notify-icon {
    color: var(--primary);
}

.notify-container.error {
    border-color: rgba(255, 59, 48, 0.2);
}

.notify-container.error .notify-icon {
    color: rgb(255, 59, 48);
}

.notify-container.info .notify-icon {
    color: var(--primary);
}

/* Animations */
@keyframes slideIn {
    from {
        transform: translateX(120%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(120%);
        opacity: 0;
    }
}

@keyframes progress {
    from {
        width: 100%;
    }
    to {
        width: 0%;
    }
}

/* Stack multiple notifications */
.notify-container + .notify-container {
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .notify-container {
        width: calc(100% - 40px);
        max-width: none;
    }
}