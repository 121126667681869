/* Hero Section */
.hero {
    padding: 160px 40px 80px;
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
}

.hero-eyebrow {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 100px;
    background: rgba(122, 255, 193, 0.1);
    border: 1px solid rgba(122, 255, 193, 0.2);
    margin-bottom: 30px;
}

.status-indicator {
    width: 8px;
    height: 8px;
    background: var(--primary);
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.hero h1 {
    font-size: 4em;
    line-height: 1.2;
    margin-bottom: 30px;
}

.gradient-text {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-description {
    color: var(--text-secondary);
    font-size: 1.2em;
    max-width: 800px;
    margin: 0 auto 60px;
}

/* Tools Showcase */
.tools-showcase {
    padding: 80px 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.tool-card {
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
    padding: 30px;
    text-decoration: none;
    color: var(--text);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    min-height: 200px;
}

.tool-card:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    background: var(--glass-border);
}

.tool-icon2 {
    font-size: 2em;
    color: var(--icon-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: rgba(0, 255, 140, 0.1);
    border-radius: 12px;
}

.tool-card h3 {
    color: var(--text);
    margin-bottom: 10px;
}

.tool-card p {
    color: var(--text-secondary);
    margin-bottom: 20px;
}

.card-link {
    color: var(--primary);
    font-weight: 500;
    text-decoration: none;
}

/* Stats Section */
.stats {
    padding: 80px 40px;
    background: var(--bg-darker);
}

.stats-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.stat-item {
    padding: 30px;
    background: var(--glass);
    border-radius: 16px;
    border: 1px solid var(--glass-border);
    text-decoration: none;
    color: var(--text);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: -5px;
    min-height: 200px;
}

.stat-item:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    background: var(--glass-border);
}

.stat-icon {
    font-size: 2em;
    color: var(--primary); /* Zmiana koloru ikony na --primary */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: rgba(0, 255, 140, 0.1);
    border-radius: 12px;
}

.stat-number {
    font-size: 2.5em;
    font-weight: bold;
    color: var(--primary);
    margin-bottom: 10px;
}

.stat-item p {
    color: var(--text-secondary);
}

/* Animations */
@keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .hero h1 {
        font-size: 3em;
    }

    .tools-showcase {
        padding: 40px 20px;
    }
}