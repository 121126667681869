:root {
    --primary: #00ff8c;
    --primary-light: #7affc1;
    --primary-dark: #00cc71;
    --accent: #4dffb8;
    --bg-dark: #0a1a15;
    --bg-darker: #050d0a;
    --icon-color: #00ff8c;
    --text: #ffffff;
    --error: #ff0000;
    --text-secondary: rgba(255, 255, 255, 0.7);
    --glass: rgba(10, 26, 21, 0.7);
    --glass-border: rgba(122, 255, 193, 0.1);
    --gradient: linear-gradient(135deg, var(--primary), var(--accent));
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: radial-gradient(circle at top right, var(--bg-dark), var(--bg-darker));
    color: var(--text);
    line-height: 1.6;
    min-height: 100vh;
    overflow-x: hidden;
    font-family: "Inter", sans-serif;
}

.unavailable-container {
    text-align: center;
    padding: 50px 20px;
    max-width: 600px;
    margin: 160px auto;
    background: var(--bg-darker);
    border: 1px solid var(--glass-border);
    border-radius: 16px;
}

.unavailable-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: var(--primary);
}

.unavailable-message {
    font-size: 1.2em;
    color: var(--text-secondary);
}
