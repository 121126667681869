/* Hero Section */
.policy-hero {
    padding: 160px 40px 80px;
    text-align: center;
    max-width: 1400px;
    margin: 0 auto;
}

.policy-eyebrow {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 100px;
    background: rgba(122, 255, 193, 0.1);
    border: 1px solid rgba(122, 255, 193, 0.2);
    margin-bottom: 30px;
}

.status-indicator {
    width: 8px;
    height: 8px;
    background: var(--primary);
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.policy-hero h1 {
    font-size: 4em;
    line-height: 1.2;
    margin-bottom: 30px;
}

.gradient-text {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.policy-description {
    color: var(--text-secondary);
    font-size: 1.2em;
    max-width: 800px;
    margin: 0 auto 60px;
}

/* Policy List Section */
.policy-list {
    padding: 80px 40px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
}

.policy-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.policy-item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    transition: all 0.3s ease;
}

.policy-item:hover {
    border-color: var(--primary);
    background: var(--glass-border);
}

.policy-number {
    font-size: 1.5em;
    font-weight: bold;
    color: var(--primary);
    background: rgba(0, 255, 140, 0.1);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;
}

.policy-content h3 {
    color: var(--text);
    font-size: 1.3em;
    margin-bottom: 10px;
}

.policy-content p {
    color: var(--text-secondary);
    font-size: 1em;
}

/* Animations */
@keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

/* Responsive Design */
@media (max-width: 768px) {
    .policy-hero {
        padding: 100px 20px 40px;
    }

    .policy-hero h1 {
        font-size: 3em;
    }

    .policy-list {
        padding: 40px 20px;
    }

    .policy-item {
        flex-direction: column;
        text-align: center;
        gap: 15px;
    }

    .policy-number {
        margin: 0 auto;
    }
}