footer {
    background: var(--bg-darker);
    padding: 80px 40px 30px;
    border-top: 1px solid var(--glass-border);
    margin-top: auto;
}

.footer-content {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 60px;
    max-width: 1400px;
    margin: 0 auto;
    justify-items: center; /* Wyśrodkowanie zawartości */
    padding: 0 20px; /* Dodanie symetrycznych marginesów */
}

/* Brand Section */
.footer-brand {
    text-align: center;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.logo-png {
    width: 32px; /* Rozmiar dostosowany do poprzedniego SVG */
    height: 32px;
    object-fit: contain; /* Zachowanie proporcji obrazu */
}

.footer-brand-name {
    font-size: 1.5rem;
    font-weight: bold;
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-description {
    color: var(--text-secondary);
    line-height: 1.6;
    margin-bottom: 25px;
}

/* Social Links */
.social-links {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.social-link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--glass);
    border: 1px solid var(--glass-border);
    border-radius: 12px;
    color: var(--text);
    transition: all 0.3s ease;
    font-size: 1.2em;
}

.social-link:hover {
    transform: translateY(-2px);
    border-color: var(--primary);
    color: var(--primary);
}

/* Footer Groups */
.footer-group {
    text-align: center; /* Wyśrodkowanie całej sekcji */
}

.footer-group h4 {
    color: var(--primary);
    font-size: 1.1em;
    margin-bottom: 25px;
    font-weight: 600;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center; /* Wyśrodkowanie linków */
}

.footer-links a {
    color: var(--text-secondary);
    text-decoration: none;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.footer-links a:hover {
    color: var(--primary);
    transform: translateX(5px);
}

/* Bottom Section */
.footer-bottom {
    max-width: 1400px;
    margin: 60px auto 0;
    padding-top: 30px;
    border-top: 1px solid var(--glass-border);
}

.footer-bottom-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright {
    color: var(--text-secondary);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr); /* 2 kolumny na średnich ekranach */
    }

    .footer-brand {
        grid-column: span 2;
        order: -1;
    }
}

@media (max-width: 768px) {
    footer {
        padding: 60px 20px 20px;
    }

    .footer-content {
        grid-template-columns: 1fr; /* Jedna kolumna na małych ekranach */
        gap: 40px;
        text-align: center; /* Wyśrodkowanie zawartości */
    }

    .footer-brand {
        grid-column: span 1;
    }

    .footer-bottom-content {
        text-align: center;
    }
}